<template>
  <ui-component-modal
    modalTitle="Credit invoice"
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="'Credit'"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="startCreditInvoice"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div class="has-margin-bottom is-size-5 has-text-centered">
          Are you sure you want to credit invoice
          {{ invoice.Year + '-' + invoice.Month + '-' + invoice.Number }}
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            :label="'Yes, I am sure!'"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'

export default {
  components: {
    BaseCheckbox: () => import('@/components/UI/Form/BaseCheckbox'),
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    invoice: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    ...mapActions('feeinvoiceStore', ['creditInvoice']),

    startCreditInvoice() {
      EventBus.$emit('showToast', {
        type: 'info',
        message: 'Invoice creditted!',
      })

      this.creditInvoice(this.invoice.Id)

      this.onClickCancel()
    },
  },
}
</script>
